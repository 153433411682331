import React from 'react';
import {motion} from 'framer-motion';
import {graphql, Link} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import Card from 'components/Card';
import Layout from 'components/Layout';
import {PageHeader} from 'components/Headings';
import {ButtonPrimary} from 'components/Button';
import {pageVariants, pageTransition} from 'components/Transitions';

import {FaAngleDoubleRight} from 'react-icons/fa';
import PageBuilder from "../components/page-builder/PageBuilder";

function CaseStudy({data}) {
  debugger
  const {
    seomatic,
    tags,
    blocks,
    clientName,
    projectName,
    screenshotAlt,
    screenshot: [screenshot],
    relatedContentEntry: {uri: relatedContentUri},
    relatedContentLabel,
    relatedCaseStudies,
  } = data.craftCaseStudiesCaseStudyEntry;

  const hasRelatedCaseStudies = relatedCaseStudies?.length > 0;
  const showRelatedContentSection = relatedContentLabel || hasRelatedCaseStudies;

  return (
    <Layout hasContact seomatic={seomatic}>
      <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
        <div className="article font-display">
          <div className="relative flex flex-col max-h-screen text-white background">
            <PageHeader heading={clientName} subheading={projectName} />
            {screenshot && (<div className="flex items-center justify-center w-full max-w-screen-xl m-auto overflow-hidden">
              <GatsbyImage layout="fullWidth" image={getImage(screenshot?.localFile)} alt={screenshotAlt} />
            </div>)
            }
          </div>
          <div className="bg-white article">
            <div className="pb-12">
              {tags && (
                <div className="flex-initial mb-8">
                  <div className="flex flex-wrap gap-2.5 items-center">
                    {tags.map(tag => (
                      <ButtonPrimary key={`tag-${tag.id}`} label={tag.title} size="small" style={{cursor: 'default'}} />
                    ))}
                  </div>
                </div>
              )}

              <PageBuilder blocks={blocks} />

            </div>

            {showRelatedContentSection && (
              <div className="w-full max-w-screen-xl pt-8 pl-6 pr-6 m-auto mt-6 border-t border-gray-500 border-opacity-20 md:mt-0">
                {relatedContentLabel && (
                  <Link to={relatedContentUri ?? '/our-work'}>
                    <h3 className="inline-block mb-6 font-bold text-transparent underline bg-gradient-to-r from-orange to-pink bg-clip-text">
                      {`${relatedContentLabel}  `}
                      <FaAngleDoubleRight color="#EA4090" className="inline text-transparent bg-gradient-to-r from-orange to-pink bg-clip-text" size="20px" />
                    </h3>
                  </Link>
                )}
                {hasRelatedCaseStudies && (
                  <div className="grid w-full max-w-screen-xl pb-10 m-auto text-black lg:pb-40 gap-y-10 md:gap-y-52 lg:grid-cols-2 lg:gap-x-12 lg:gap-y-32 xl:gap-x-24 xl:pl-0 xl:pr-0">
                    {relatedCaseStudies.filter(({caseStudy}) => !!caseStudy).map(
                      ({
                        id,
                        heading,
                        shortDescription,
                        heroImage: [heroImage],
                        heroImageAlt,
                        caseStudy: [
                          {
                            clientName,
                            tags,
                            uri,
                            heroImage: [caseStudyHeroImage],
                            heroImageAlt: caseStudyHeroImageAlt,
                          },
                        ],
                      }) => (
                        <Card
                          key={`related-case-study-${id}`}
                          heading={heading}
                          client={clientName}
                          blurb={shortDescription}
                          tags={tags}
                          path={uri}
                          heroImage={heroImage ?? caseStudyHeroImage}
                          alt={heroImageAlt ?? caseStudyHeroImageAlt}
                        />
                      )
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </motion.div>
    </Layout>
  );
}

export const query = graphql`
  fragment CaseStudyImage on Craft_assets_Asset {
    localFile {
      childImageSharp {
        gatsbyImageData(width: 1024, height: 768, quality: 80)
      }
    }
  }
  query ($id: String) {
    craftCaseStudiesCaseStudyEntry(id: {eq: $id}) {
      seomatic {
        ...SeomaticFields
      }
      title
      intro
      description
      clientName
      projectName
      tags {
        id
        title
      }

      # START OF BLOCKS
      blocks {
        ...on Craft_blocks_article_BlockType {
          typeHandle
          heroBannerImage {
            ... on Craft_assets_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1024, height: 768, quality: 80)
                  id
                }
              }
            }
          }
          workingWithUsHeading
          workingWithUsBody
        }
        ...on Craft_blocks_caseStudies_BlockType {
          typeHandle
          heading
          caseStudies {
            ...on Craft_caseStudies_BlockType {
              id
              heading
              highlightedHeading
              shortDescription
              readMoreLabel
              heroImage {
                ... on Craft_assets_Asset {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 800, height: 600, quality: 80)
                    }
                  }
                }
              }
              caseStudy {
                ... on Craft_caseStudies_caseStudy_Entry {
                  uri
                  tags {
                    id
                    title
                  }
                }
              }
            }
          }
        }
        ...on Craft_blocks_fullWidthScreenshot_BlockType {
          typeHandle
          screenshot {
            ... on Craft_assets_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1280, height: 600, quality: 80, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          screenshotAlt
        }
        ...on Craft_blocks_keypoints_BlockType {
          typeHandle
          heading
          backgroundColour
          callToActionButtonLabel
          callToActionEntry {
             uri
          }
          callToActionMailTo
          keypoints {
            ...on Craft_keypoints_BlockType {
              id
              keypointTitle
              keypointContent
            }
          }
        }
        ...on Craft_blocks_largeVideo_BlockType {
          typeHandle
          heading
          description
          embedUrl
          ctaText
          callToActionUrl
          placeholderImage {
            url
          }
          placeholderImageAlt
        }
        ...on Craft_blocks_logos_BlockType {
          typeHandle
          heading
          backgroundColour
          logos {
            ...on Craft_logos_BlockType {
              id
              partnerName
              partnerLink
              logo {
                ... on Craft_assets_Asset {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 200, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
        ...on Craft_blocks_process_BlockType {
          typeHandle
          heading
          process {
            ...on Craft_process_BlockType {
              id
              heading
              description
              image {
                ... on Craft_assets_Asset {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(height: 60, layout: CONSTRAINED)
                    }
                  }
                }
              }
              entry {
                id
                uri
              }
            }
          }
        }
        ...on Craft_blocks_cta_BlockType {
          typeHandle
          backgroundColour
          callToActionButtonLabel
          callToActionMailTo
          callToActionEntry {
            uri
            title
          }
        }
        ...on Craft_blocks_quote_BlockType {
          typeHandle
          speechMarksColour
          text
          caption
        }
        ...on Craft_blocks_resources_BlockType {
          typeHandle
          heading
          resources {
            ...on Craft_resources_BlockType {
              id
              heading
              shortDescription
              heroImage {
                ... on Craft_assets_Asset {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 460, height: 210, quality: 80)
                    }
                  }
                }
              }
              resource {
                id
                uri
              }
            }
          }
        }
        ...on Craft_blocks_testimonials_BlockType {
          typeHandle
          heading
        }
        ...on Craft_blocks_textAndImageVideo_BlockType {
          typeHandle
          text
          heroImageAlt
          embedUrl
          backgroundColour
          heroImage {
            ... on Craft_assets_Asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1024, height: 768, quality: 80)
                  id
                }
              }
            }
          }
        }
        ...on Craft_blocks_whatWeDo_BlockType {
          typeHandle
          heading
          description
          backgroundColour
          underlineColour
          ourExpertise {
            ...on Craft_ourExpertise_BlockType {
              heading
              description
              entry {
                id
                uri
              }
            }
          }
        }
        ...on Craft_blocks_wysiwyg_BlockType {
          typeHandle
          wysiwygContent
          backgroundColour
        }
      }
      # END OF BLOCKS

      screenshotAlt
      screenshot {
        ... on Craft_assets_Asset {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1280, height: 600, quality: 80, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
      relatedContentEntry {
        uri
      }
      relatedContentLabel
      relatedCaseStudies {
        ... on Craft_relatedCaseStudies_caseStudies_BlockType {
          id
          heading
          shortDescription
          heroImageAlt
          heroImage {
            ...CaseStudyImage
          }
          caseStudy {
            uri
            ... on Craft_caseStudies_caseStudy_Entry {
              clientName
              heroImageAlt
              heroImage {
                ...CaseStudyImage
              }
              tags {
                id
                title
              }
            }
          }
        }
      }
    }
  }
`;

export default CaseStudy;
